<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
        >
            <template v-slot:filters>
                <Dropdown
                    name="period"
                    :title="filterTitles.period"
                    :options="filterOptions.period"
                    :disabled="mode === 'admin'"
                    :placeholder="placeholder"
                    v-model="dropdownPeriodValue"
                />
                <Dropdown
                    name="month"
                    :title="filterTitles.month"
                    :options="filterOptions.month"
                    :disabled="mode === 'admin'"
                    :placeholder="placeholder"
                    v-model="dropdownMonthValue"
                />
                <TitleTypeAccountFilter
                    ref="titleTypeAccountFilter"
                    :required="false"
                    :isUseTitleType="false"
                    v-if="isShowManCodeFilter"
                    v-model:manCode="dropdownManCodeValue"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import _ from 'lodash'
import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'
import { getCommissionCodesAPI } from '@/assets/javascripts/api'
import { commissionFilterTitles } from '@/assets/javascripts/commission/setting'

export default {
    name: 'CommissionFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        Dropdown,
        FilterModal,
        TitleTypeAccountFilter
    },
    props: {
        mode: {
            type: String,
            default: 'history'
        },
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    period: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData: function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            } else if (this.dropdownOptions?.period.length === 0) {
                //帳號無年期選項可以選擇
                this.$emit('show-data-table')
                this.placeholder = '無'
            }
            this.filterLog = this.$getFilterLog({
                period: this.dropdownPeriodValue,
                month: this.dropdownMonthValue
            })
        },
        resetFilter: function () {
            // this.filterPayload 跟著 dropdownPeriodValue ＆ dropdownMonthValue 一起變
            const periodOption = this.dropdownOptions.period[0]
            this.dropdownPeriodValue = periodOption?.substring(0, 4) ?? null
            this.dropdownMonthValue = periodOption?.substring(4, 6) ?? null
            if (this.isShowManCodeFilter) {
                this.$refs.titleTypeAccountFilter.resetFilter()
            }
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getCommissionCodesAPI()

                this.dropdownOptions = this.$transformInputType(res.data)
                this.dropdownOptions.period = this.dropdownOptions?.period.sort(
                    (a, b) => b - a
                )
            } catch (error) {
                this.$showErrorMessage('系統發生問題，請稍後重新整理再試')
            } finally {
                this.$hideLoading()
            }
        },
        setPayload: function () {
            const period = this.dropdownPeriodValue
            const month = this.dropdownMonthValue
            const manCode = this.dropdownManCodeValue
            if (period && month) {
                this.filterPayload.period = [
                    this.dropdownPeriodValue + this.dropdownMonthValue
                ]
            }
            // 只填年 -> 幫補回1~12月
            if (period && !month) {
                this.filterPayload.period = this.filterOptions.month
                    .filter((option) => option.value)
                    .map((option) => this.dropdownPeriodValue + option.value)
            }
            // 只填月 -> 幫補回全部年
            if (!period && month) {
                this.filterPayload.period = this.filterOptions.period
                    .filter((option) => option.value)
                    .map((option) => option.value + this.dropdownMonthValue)
            }

            // 填入manCode
            if (manCode) {
                this.filterPayload.manCodes = [manCode]
            } else {
                delete this.filterPayload.manCodes
            }
        }
    },
    computed: {
        isShowManCodeFilter: function () {
            return this.mode !== 'admin' && this.$isAdmin()
        },
        /* filter dropdown options */
        periodBasicOption: function () {
            return this.dropdownMonthValue ? this.allOption : []
        },
        monthBasicOption: function () {
            return this.dropdownPeriodValue ? this.allOption : []
        },
        filterOptions: function () {
            return {
                period: this.periodBasicOption.concat(
                    _.uniqBy(
                        this.dropdownOptions.period
                            .filter((data) => data)
                            .map((data) => {
                                return {
                                    label: data.substring(0, 4),
                                    value: data.substring(0, 4)
                                }
                            }),
                        (option) => option.value
                    ).sort((a, b) => Number(b.value) - Number(a.value))
                ),
                month: this.monthBasicOption.concat(
                    _.uniqBy(
                        this.dropdownOptions.period
                            .filter((data) => {
                                if (
                                    this.dropdownPeriodValue.length > 0 &&
                                    data.substring(0, 4) ==
                                        this.dropdownPeriodValue
                                )
                                    return data
                                else if (this.dropdownPeriodValue.length < 1)
                                    return data
                            })
                            .map((data) => {
                                return {
                                    label: data.substring(4, 6),
                                    value: data.substring(4, 6)
                                }
                            }),
                        (option) => option.value
                    ).sort((a, b) => Number(a.value) - Number(b.value))
                ),
                manCode: _.orderBy(
                    this.$store.state.accounts.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    }),
                    'label',
                    'asc'
                )
            }
        }
    },
    watch: {
        dropdownPeriodValue: function () {
            if (this.dropdownPeriodValue === '')
                this.$setGaEvent('allPeriodValue', 'filter-Option')
            else this.$setGaEvent('onePeriodValue', 'filter-Option')
            this.setPayload()
        },
        dropdownMonthValue: function () {
            if (this.dropdownMonthValue === '')
                this.$setGaEvent('allMonthValue', 'filter-Option')
            else this.$setGaEvent('oneMonthValue', 'filter-Option')
            this.setPayload()
        },
        dropdownManCodeValue: function () {
            this.setPayload()
        },
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            dropdownOptions: { period: [] },
            /* 控制顯示選項用 */
            dropdownPeriodValue: '',
            dropdownMonthValue: '',
            dropdownManCodeValue: '',

            filterTitles: commissionFilterTitles,
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            placeholder: undefined
        }
    },
    async mounted() {
        if (this.isShowManCodeFilter) {
            this.$nextTick(() => {
                const titleTypeAccountTitles = this.$refs.titleTypeAccountFilter
                    .filterTitles
                this.filterTitles = {
                    ...commissionFilterTitles,
                    ...titleTypeAccountTitles,
                    _seq: [
                        ...commissionFilterTitles._seq,
                        ...titleTypeAccountTitles._seq
                    ]
                }
            })
        }
        await this.getDropdownOptions()
        this.resetFilter()
        this.$nextTick(() => {
            if (this.$route.name === 'CommissionAdmin') this.getFilterData()
        })
    }
}
</script>
<style lang="scss" scoped></style>
